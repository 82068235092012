// Write your custom style. Feel free to split your code to several files
body {
  .navbar-brand img {
    max-width: inherit;
    height: 42px;
  }

  .popular{
    position: relative;
    box-shadow: 0px 3px 10px rgba(0,0,0,0.15);

    .popular-tag{
      position: absolute;
      bottom: 100%;
      left: 0;
      width: 100%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      color: $white;
      background: $green;
      text-transform: uppercase;
      font-weight: 400;
      font-size: 0.75rem;
    }
  }

  .navbar-dark{
    &:not(.stick){
      .nav-navbar{
        .nav-link{
          color: #ebebeb;
        }
      }

      .logo-dark,
      .social-dark{
        display: none;
      }

      .logo-light,
      .social-light{
        display: block;
      }
    }

    .backdrop-navbar {
      background-color: rgba(0, 0, 0, 0.75);
    }

    .social-light{
      display: none;
    }
  }

  &.navbar-open{
    .navbar-dark{
      &:not(.stick){
        .nav-navbar{
          .nav-link{
            color: #757575cc;
          }
        }
      }
    }
  }

  .or{
    display: inline-block;
    margin: 0 1rem;

  }

  .drag-wrap{
    .info{
      font-size: 14px;
      font-weight: 400;
      display: inline-block;
      animation: 4s horizontal-shake ease-in-out infinite;

      .icon{
        position: relative;
        top: 2px;
        color: $blue;
        font-size: 20px;
      }
    }
  }

  @keyframes horizontal-shake{
    0%{transform:translate(0,0) rotate(0)}
    25%{transform:translate(8px,0) rotate(0)}
    50%{transform:translate(0,0) rotate(0)}
    75%{transform:translate(8px,0) rotate(0)}
    100%{transform:translate(0,0) rotate(0)}
  }

  .custom-range{
    &::-webkit-slider-thumb {
      margin-top: -0.5rem;
    }
  }

  .bg-blue{
    background-color: $blue;
  }

  .text-blue{
    color: $blue;
  }

  .with-save-20{
    position: relative;

    .save-20{
      position: absolute;
      top: 3px;
      left: calc(100% + 14px);
      color: $white;
      background-color: $green;
      padding: .25rem .75rem;
      font-weight: 400;

      &:before{
        content: '';
        display: block;
        position: absolute;
        top: 8px;
        left: -5px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 5px 5px 0;
        border-color: transparent $green transparent transparent;
      }

      @media (max-width: 576px){
        top: calc(100% + 8px);
        left: 20px;

        &:before{
          top: -5px;
          left: calc(50% - 5px);
          border-width: 0 5px 5px 5px;
          border-color: transparent transparent $green transparent;
        }
      }
    }
  }

  #navbar:not(.is-loaded){
    opacity: 0;
    transform: translate(0, -100px);
  }

  transition: all 150ms ease-in-out;
  &.popup-open{
    > .blurrable {
      // filter: blur(2px); // @TODO someday

      &:after {
        content: '';
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.75);
      }
    }
  }
}

// Expanded sidebar nav
body.navbar-open{
  .navbar-dark{
    .social-dark{
      display: inline-block;
    }

    .social-light{
      display: none;
    }
  }
}

.input-group{
  &.is-disabled{
    .input-group-prepend,
    .input-group-append{
      .input-group-text{
        background-color: #e9ecef;
      }
    }
  }
}

.popup{
  box-shadow: 0 0 15px rgba(0,0,0,0.15);

  &[data-position="top-center"]{
    top: 150px;
  }
}

#modal-email-list{
  max-width: 800px;
}

.old-price{
  position: relative;
  color: #bfbfbf;

  &:after{
    content: '';
    position: absolute;
    top: 0;
    left: calc(50% - 1px);
    width: 2px;
    height: 100%;
    transform: rotate(60deg);
    background: #bfbfbf;
  }

}

.sale-pricing{
  left: calc(100% + 14px);
  color: $white;
  background-color: $green;
  padding: .25rem 1.5rem;
  font-size: 14px;
  font-weight: 300;
  animation: shake 600ms ease-in-out alternate infinite;
}

@keyframes shake{
  0%{
    transform: translate(0, -2px);
  }
  100%{
    transform: translate(0, 2px);
  }
}
